import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanProgramsGridRow";
// import HomeWorth from "../components/Repeating/HomeWorth";
import ValueProps from "../components/Repeating/ValueProps";
import FAQs from "../components/Repeating/FAQs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

import benefit1 from "../images/3.0 Services/Reverse Mortgages/make home improvements.svg";
import benefit2 from "../images/3.0 Services/Reverse Mortgages/Free up capital for a major purchase.svg";
import benefit3 from "../images/3.0 Services/Reverse Mortgages/Collect monthly payments.svg";
import benefit4 from "../images/3.0 Services/Reverse Mortgages/Enjoy a lower interest rate.svg";

const Page = ({ data }) => {
  const faqs = [
    {
      question:
        "What’s the difference between a reverse mortgage and a traditional home equity loan?",
      answer: (
        <>
          <p>
            A reverse mortgage and a traditional home equity loan are very
            similar in that they are both loans secured by your home and incur
            interest charges. With both loans, you remain the owner of your home
            with the ability to sell the property so long as you pay the loan in
            full at the sale.
          </p>
          <p>
            However, with a reverse mortgage, you can either (1) take out a lump
            sump OR (2) request a monthly distribution of cash OR (3) get a
            combination of these options. Regardless of the option(s) selected,
            you do not have to make a monthly mortgage payment because the
            interest due under the loan is allowed to accrue against the value
            of your home (and can even accrue beyond the value of your home).
          </p>
          <p>
            Most importantly, you will not be in default on a reverse mortgage
            for the failure to make mortgage payments. So long as you timely pay
            your property taxes and homeowners insurance premiums, remain in
            your home as your primary residence, and maintain the property, you
            are fully protected from any default or foreclosure, even if the
            loan balance eventually exceeds the value of your home.{" "}
          </p>
          <p>
            And, upon the death of the last borrower on the reverse mortgage to
            die (since the reverse mortgage cannot come due when only one of
            multiple borrowers dies), your home is the SOLE asset to which the
            lender can look to satisfy any balance on the reverse mortgage, even
            if the balance of the reverse mortgage exceeds the value of your
            home.
          </p>
          <p>
            If you choose, you can make monthly payments on your reverse
            mortgage to keep the loan balance from increasing or even to lower
            your loan balance. This is entirely optional and some homeowners
            elect this option to increase the equity available to give to their
            heirs.
          </p>
        </>
      ),
    },
    {
      question: "What’s the minimum age to qualify?",
      answer: (
        <>
          <p>You have to be at least 55 years old to get a reverse mortgage.</p>
        </>
      ),
    },
    {
      question: "When do I have to repay the reverse mortgage?",
      answer: (
        <>
          <p>
            A reverse mortgage has no payment obligations during the life of the
            borrowers. However, the reverse mortgage will need to be fully
            repaid upon the first of the following:
          </p>
          <ul className="styled-list-checkmark mb-6">
            <li>If your home is no longer your primary residence,</li>
            <li>
              Once the last of the borrowers on the reverse mortgage passes
              away,
            </li>
            <li>
              If you fail to timely pay both your property taxes and homeowners
              insurance premiums, or
            </li>
            <li>If you neglect to maintain your home.</li>
          </ul>
        </>
      ),
    },
    {
      question: "Can I use a reverse mortgage to buy another property?",
      answer: (
        <>
          <p>
            Yes! While you are required to reside in the home on which you took
            out the reverse mortgage, you are allowed to use the money to
            purchase in income-producing property, a second home, or a vacation
            property. It’s a great way to free up the needed capital to make
            another worthwhile investment.
          </p>
        </>
      ),
    },
    {
      question: "If I already have a reverse mortgage, can I refinance it?",
      answer: (
        <>
          <p>
            The good thing about reverse mortgages is that they can be
            refinanced, so, depending on the market, you can get cash in hand, a
            new line of credit, a monthly payment to you and/or a lower rate and
            end up owing less.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Reverse Mortgage Lender Orange County | Nikkael Home Loans"
        description="Looking for Orange County’s Leading Reverse Mortgage Broker? Contact us today to help you find the best reverse mortgage lender that will fit your goals."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 pt-10 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Leading Reverse Mortgage Broker in Orange County & Beyond</h1>
              <p>
                When used properly, a reverse mortgage is a wonderful tool for
                those over 55 years of age. Homeowners can use a reverse
                mortgage to convert their hard-earned equity into cash. It can
                also be used to refinance a standard mortgage into a reverse
                mortgage to eliminate the need to make monthly mortgage payments
                in the future.
              </p>
              <p>
                You can use the money from a reverse mortgage on your home in
                Orange County or located elsewhere in California to either to
                purchase a new home or refinance your existing home, pay off
                debt, make home improvements, or pay for long-term care. In some
                situations, a reverse mortgage can be used to cure a mortgage
                default and save your home.
              </p>
              <h2>We’re Attorneys Too</h2>
              <p>
                As attorney/mortgage brokers, we’ll help you find the best
                reverse mortgage for your situation and make sure you get the
                full benefits available. We are also able to address the many
                myths about reverse mortgages, which may have kept you from
                finding out how a reverse mortgage may be perfect for your
                situation.
              </p>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/3.0 Services/Reverse Mortgages/1.0 Reverse Mortgages.jpg"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-8 md:grid-cols-2">
            <div className="">
              <header className="mb-12 md:mb-16">
                <h2>The Advantages of a Reverse Mortgage</h2>
              </header>
              <div className="col-span-10 grid gap-y-8 md:grid-cols-1 md:gap-x-10 lg:gap-x-20">
                <div className="items-start md:flex md:space-x-8">
                  <div>
                    <h3 className="heading-four mb-2">
                      Help secure your retirement by converting equity to cash
                    </h3>
                    <p className="mb-0">
                      A reverse mortgage can be perfect for retirees who do not
                      have a lot of available cash but do have built-up equity
                      in their homes. A reverse mortgage will allow you to
                      convert otherwise inaccessible equity into cash that can
                      be used to cover expenses in retirement (such as credit
                      card bills, medical debts, home repairs, or basic living
                      expenses)
                    </p>
                  </div>
                </div>
                <div className="items-start md:flex md:space-x-8">
                  <div>
                    <h3 className="heading-four mb-2">
                      Consolidate your debt or pay it off altogether
                    </h3>
                    <p className="mb-0">
                      Even if you're in default on your debt, it doesn't matter
                      since your credit is irrelevant to your ability to qualify
                      for a reverse mortgage (provided you have enough equity in
                      your home).
                    </p>
                  </div>
                </div>
                <div className="items-start md:flex md:space-x-8">
                  <div>
                    <h3 className="heading-four mb-2">
                      Buy a new home or income-producing property
                    </h3>
                    <p className="mb-0">
                      Use the cash from the reverse mortgage to purchase a new
                      primary residence or an income-producing property without
                      needing to make any mortgage payments for the rest of your
                      life.
                    </p>
                  </div>
                </div>
                <div className="items-start md:flex md:space-x-8">
                  <div>
                    <h3 className="heading-four mb-2">
                      Pay off your existing home loan and potentially eliminate
                      monthly mortgage payments
                    </h3>
                    <p className="mb-0">
                      You can use the money from a reverse mortgage to pay off
                      an existing mortgage. This will free up your money to pay
                      other living expenses or secure your retirement. With a
                      reverse mortgage, you don’t have to make monthly mortgage
                      payments because you can opt to have the mortgage
                      payment(s) added to the balance of your reverse mortgage.
                    </p>
                  </div>
                </div>
                <div className="items-start md:flex md:space-x-8">
                  <div>
                    <h3 className="heading-four mb-2">
                      Cure mortgage defaults and avoid foreclosure
                    </h3>
                    <p className="mb-0">
                      If your current mortgage is in default or even in
                      foreclosure, a reverse mortgage can be used to cure the
                      default, remove the foreclosure, and pay off the entire
                      home loan. You may even be able to get some cash, too!
                    </p>
                  </div>
                </div>
                <div className="items-start md:flex md:space-x-8">
                  <div>
                    <h3 className="heading-four mb-2">
                      You still own your home and can sell it as you wish
                    </h3>
                    <p className="mb-0">
                      There are rumors that the lender will take your home from
                      you. This is a false statement as long as you pay your
                      property taxes and homeowners insurance premiums, remain
                      in your home as your primary residence and maintain the
                      property. It is YOUR decision when you sell the home, if
                      ever.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:p-14 lg:px-[55px]">
              <div className="mx-auto lg:w-[350px]  bg-[#F2F6F7] px-[15px] text-center md:min-w-[350px] py-[25px]">
                <div className="flex-center flex flex-col p-2">
                  <h3 className="mb-[5px] text-[30px]">Ready to Get Started?</h3>
                  <p className="p-[5px]">
                    We’re happy to guide you to the right choice for you—contact
                    Nikkael Home Loans today!
                  </p>
                  <ButtonSolid modal="modal-contact" text="Contact Us" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/3.0 Services/eligibility.jpg"
                loading="lazy"
                width={560}
              />
            </div>
            <div>
              <h2>Am I Eligible?</h2>
              <p>To apply for a reverse mortgage with a lender you need to:</p>
              <ul className="styled-list-checkmark mb-6">
                <li>Be 55 or over</li>
                <li>Have significant equity in your home</li>
                <li>
                  Occupy the home as your primary residence for the life of the
                  reverse mortgage
                </li>
                <li>
                  Undergo counseling with a HUD-approved mortgage counselor
                </li>
              </ul>
              <p>
                Contact us today to go over your options and start an
                application for a reverse mortgage!
              </p>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
          </div>
        </div>
      </section>

      {/* <HomeWorth /> */}
      <FAQs
        heading="Common Questions About Reverse Mortgages"
        uniqueFaqs={faqs}
      />
      <ValueProps />
      <Testimonials />
      <LoanPrograms
        heading="You Might Also Be Interested In"
        hidePrograms={[0 , 5]}
      />
      <CallToAction
        heading="Want More Information About a Reverse Mortgage?"
        text="We’re a top reverse mortgage broker in Orange County, and we’re armed with answers to all your questions. Contact us today!"
      />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-reverse-mortgage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-reverse-mortgage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
